.cookie-bar {
    bottom: 0;
    height: 0px;
    left: 0;
    position: fixed;
    width: 100%;
    display: none;
    padding: 0px 30px;
    z-index: 99999;

    &.-open {
        display: block;
    }

    .cookie-bar-inner {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.9);
        border-radius: 3px;
        border: 2px solid black;
        bottom: 20px;
        color: white;
        display: flex;
        justify-content: space-between;
        margin: 0px auto 20px auto;
        max-width: 1800px + 50px;
        padding: 10px;
        position: relative;
        transform: translateY(-100%);
        font-size: 14px;

        .cookie-bar-slot {
            p {
                margin: 0;
            }

            a {
                color: white;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            button {
                white-space: nowrap;
            }

            &:nth-child(1) {
                flex-grow: 1;
            }

            &:nth-child(2) {
                a {
                    display: block;
                    padding: 20px;
                    white-space: nowrap;
                }
            }
        }

        @media (max-width: 900px) {
            display: flex;
            flex-direction: column;
        }
    }
}

.cookie-popup {
    background-color: rgba(0, 0, 0, 0.9);
    display: none;
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;

    &.-open {
        display: block;
    }

    .cookie-popup-inner {
        background-color: white;
        border-radius: 3px;
        border: 1px solid black;
        color: black;
        font-size: 18px;
        margin: 50px auto;
        max-width: 600px;
        padding: 20px 0px;
        font-size: 16px;

        > h2 {
            color: black;
            font-size: 18px;
            font-weight: bold;
            margin: 0 20px;
        }

        > p {
            margin: 0 20px;
        }

        > button {
            margin: 0px 20px;
        }
    }
}

.cookie-group-list {
    background-color: lightgray;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    margin: 20px 0px;

    .cookie-group-item {
        align-items: center;
        border-bottom: 1px solid gray;
        display: flex;
        padding: 20px 20px;

        &:last-child {
            border-bottom: 0;
        }

        input {
            margin-right: 20px;
        }

        label {
            cursor: pointer;
            font-weight: bold;
        }

        p {
            margin: 0;
        }
    }
}